//*// BREADCRUMBS //*//

#plone-breadcrumb {
  background-color: $breadcrumb-bg;
  margin-bottom: 2rem;

  .breadcrumb {
    background-color: transparent;
  }

}