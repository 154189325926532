// // DEBUG MEDIA QUERIES
// @include media-breakpoint-up($nav-main-breakpoint) {
//   #content-header::before {
//     content: "main";
//     position: sticky;
//     top: 1em;
//     left: 0;
//   }
// }
// @include media-breakpoint-up(xs) {
//   body::before {
//     content: "xs";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }
// @include media-breakpoint-up(sm) {
//   body::before {
//     content: "sm";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }
// @include media-breakpoint-up(md) {
//   body::before {
//     content: "md";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }
// @include media-breakpoint-up(lg) {
//   body::before {
//     content: "lg";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }

//*// HEADER //*//
#portal-header {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  margin: $spacer * 1.25 auto $spacer * 1.25;

  @include media-breakpoint-up($nav-main-breakpoint) {
    position: relative;
    @include make-row(0rem);
    margin: $spacer * 1.25 auto $spacer * 1.25;
    flex-wrap: wrap-reverse;
  }
}


#portal-logo {
  order: -1;
  flex-grow: 1;
  width: 100%;
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

#portal-languageselector {
  display: inline-block;
  margin-bottom: 0;
  padding: 5px 0 0 0;
  list-style: none;
  margin-left: $spacer;

  li {
    display: inline-block;
    margin-left: 10px;
  }
}

#portal-anontools {
  padding-top: 5px;
  margin-left: $spacer;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin-right: 10px;
    }
  }
}

#portal-membertools-wrapper {
  margin-top: 5px;
  margin-left: $spacer * 1.5;
  margin-right: $spacer * 0.5;

  ul {
    margin: 0 0 $spacer $spacer;
    padding-right: 0;
  }
}

//mobile search
#portal-searchbox {
  order: -2;
  margin-left: 3px;
  white-space: nowrap;
  z-index: 2;
  width: 100%;
  margin: 0;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }

  @include media-breakpoint-down($nav-main-breakpoint) {
    .form-inline {
      display: block;
    }

    margin: $spacer 0;

    .searchField {
      width: 100%;
    }

    .searchSection {
      display: none;
    }

    //non on mobile
    [type="submit"] {
      display: none;
    }

    //submit hidden on mobile
    label {
      font-size: 86.667%
    }
  }

}

//non mobile search
@include media-breakpoint-up($nav-main-breakpoint) {
  #portal-searchbox {
    margin-top: 5px; //visual balance
    max-width: 50%;

    [type="text"] {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      width: 20rem;
    }

    [type="submit"] {
      display: inline-block;
      margin-left: -1px;
      vertical-align: top;
      width: auto;
      display: inline-block;
    }
  }
}