#portal-footer-wrapper {
    background-color: #2e3133;
    color: #ccc;
    text-align: center;
    padding: 1rem;

  .doormat {}

  .copyright {
    padding: 0.5rem;
  }

  .colophon {
    padding: 0.5rem;
  }

  .site-actions {
    padding: 0.5rem;

    ul {
      padding: 0;
      margin: 0;

      li {
        display: inline;
        margin: 0 0.5rem;
      }

    }

  }

}